import React, { FC } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/styles";
import { Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  banner: {
    backgroundColor: "#6d57a8",
    width: "100%",
    minHeight: 70,
    marginTop: 7,
  },
  bannerContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
  },
  bannerTitle: {
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14,
    margin: 16,
  },
  bannerTitleHiglight: {
    fontWeight: 700,
  },
  workshopLink: {
    color: "#fff",
    paddingLeft: "6px",
  },
}));

interface IProps {}
const DiscoverMoreBanner: FC<IProps> = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.banner}>
      <div className={classes.bannerContent}>
        <div className={classes.bannerTitle}>
          <span className={classes.bannerTitleHiglight}>
            Nulia Works now includes Copilot for Microsoft 365.{" "}
          </span>
          <span>
            With this exciting addition to our Change as a Service solution,
            employees develop skills to use Copilot across Microsoft 365 in new
            and better ways, maximizing the value of Copilot & ALL of Microsoft
            365.
          </span>
          <a className={classes.workshopLink} href="/workshop">
            Discover more.
          </a>
        </div>
      </div>
    </div>
  );
};

export default DiscoverMoreBanner;
