import React, { FC } from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image/withIEPolyfill";
import { FluidObject } from "gatsby-image";
import PurpleAccent from "./icons/PurpleAccent";
import { useTheme } from "@material-ui/core";

interface IProps {
  alt: string;
  desktopImage: FluidObject;
  mobileImage: FluidObject;
  backgroundPosition?: string;
}

const PurpleAccentHero: FC<IProps> = ({
  alt,
  desktopImage,
  mobileImage,
  children,
  backgroundPosition,
}) => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {!isMdDown && (
        <div
          style={{
            height: 640,
          }}
        >
          <BackgroundImage
            Tag="div"
            fluid={desktopImage}
            alt={alt}
            durationFadeIn={250}
            style={{
              height: 600,
              backgroundPosition: `top ${backgroundPosition || "center"}`,
              backgroundRepeat: "none",
              backgroundSize: "cover",
              position: "relative",
            }}
          >
            {children}
            <PurpleAccent
              style={{
                position: "absolute",
                bottom: -15,
                right: 0,
                width: "100%",
              }}
            />
          </BackgroundImage>
        </div>
      )}
      {isMdDown && (
        <>
          <div style={{ position: "relative" }}>
            <Img
              fluid={mobileImage}
              alt={alt}
              durationFadeIn={250}
              objectFit="cover"
              objectPosition="center 20%"
              style={{
                maxHeight: "65vh",
              }}
            />
            <PurpleAccent
              style={{
                position: "absolute",
                bottom: -3,
                right: 0,
                width: "105%",
              }}
            />
          </div>
          {children}
        </>
      )}
    </>
  );
};

export default PurpleAccentHero;
