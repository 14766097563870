import React, {CSSProperties, FC} from "react"

interface IProps {
    style: CSSProperties
}

const PurpleAccent: FC<IProps> = ({ style }) => (
    <svg aria-hidden="true" focusable="false" viewBox="0 0 1280 184" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <g filter="url(#filter0_dd)">
            <path d="M95 163L1280 11V163H95Z" fill="url(#paint0_linear)"/>
        </g>
        <path d="M0 170L1280 82V180H0V170Z" fill="#FAFAFA"/>
        <defs>
            <filter id="filter0_dd" x="79" y="0" width="1217" height="184" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="2"/>
                <feGaussianBlur stdDeviation="4"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="5"/>
                <feGaussianBlur stdDeviation="8"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
            </filter>
            <linearGradient id="paint0_linear" x1="687.5" y1="87" x2="687.5" y2="163" gradientUnits="userSpaceOnUse">
                <stop stopColor="#654EA3"/>
                <stop offset="1" stopColor="#44356F"/>
            </linearGradient>
        </defs>
    </svg>
)

export default PurpleAccent
